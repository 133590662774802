import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { departamentos } from "../../../../assets/utils/Departamentos";
import styles from "../Perfil/Perfil.module.css";
import { FaCheckCircle } from "react-icons/fa";
import { ButtonClosedForm } from "../../../../homeComun/components/ButtonClosedForm";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

export const FormularioCambioDatos = ({ setMostrarFormulario }) => {
  const { token, userType } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [botonSeleccionado, setBotonSeleccionado] = useState(null);
  const [thankYouPopupOpen, setThankYouPopupOpen] = useState(false);
  const [formularioVisible, setFormularioVisible] = useState(true);

  // console.log(userType);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const desplegarCaja = (boton = null) => {
    setBotonSeleccionado(boton);
    setFormularioVisible(true); // Mostrar el formulario cuando se selecciona un botón
  };

  const departamentoSeleccionado = watch("departamento");

  const handleClosePopup = () => {
    setThankYouPopupOpen(false);
    setFormularioVisible(false);
    setMostrarFormulario(false);
  };

  const municipiosFiltrados =
    departamentos.departamentos.find(
      (dep) => dep.nombre === departamentoSeleccionado
    )?.municipios || [];

  const onSubmitFormulario = async (data) => {
    setLoading(true);
    const requestData = {
      nombre: data.nombre,
      numeroTelefono: data.numeroTelefono,
      direccion: data.direccion,
      departamento: data.departamento,
      municipio: data.municipio,
      email: data.correo,
      tipo: userType,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/clientes/send-update-info`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        setThankYouPopupOpen(true);
        setFormularioVisible(false); // Ocultar el formulario

        // Cerrar el popup de agradecimiento después de 5 segundos
        setTimeout(() => {
          handleClosePopup();
        }, 5000);
      } else {
        toast.error("Ocurrió un problema de red. Por favor inténtelo más tarde");
        console.error("Error al enviar los datos");
      }
    } catch (error) {
      toast.error("Ocurrió un problema de red. Por favor inténtelo más tarde");
      console.error("Error en la solicitud:", error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className={styles.conteinerPopup}>
      {formularioVisible && (
        <div className={styles.contenedorFormularioVisible}>
          <ButtonClosedForm setOpenPopup={setMostrarFormulario} />
          <h2 className={styles.actualizacionDatos}>Actualización de datos</h2>
          {botonSeleccionado === null && (
            <>
              <p className={styles.parrafoForm}>
                Para actualizar tus datos, necesitamos que indiques qué ítem
                deseas cambiar. Luego, llenarás un formulario. Al terminarlo,
                dale clic en enviar y nuestro equipo te enviará un mensaje de
                texto cuando tus datos estén actualizados en la página.
              </p>
              <div className={styles.contenedorForm}>
                <button
                  className={styles.btnForm}
                  onClick={() => desplegarCaja(1)}
                >
                  Deseo cambiar solo <b>mi número de teléfono</b>
                </button>
                <button
                  className={styles.btnForm}
                  onClick={() => desplegarCaja(2)}
                >
                  Deseo cambiar solo <b>mi dirección</b>
                </button>
                <button
                  className={styles.btnForm}
                  onClick={() => desplegarCaja(3)}
                >
                  Deseo cambiar mi <b>correo electrónico</b>
                </button>
                <button
                  className={styles.btnForm}
                  onClick={() => desplegarCaja(4)}
                >
                  Deseo editar <b>todos mis datos</b>
                </button>
              </div>
            </>
          )}

          {/* Renderizado condicional de los formularios según el botón seleccionado */}
          {botonSeleccionado === 1 && (
            <form
              className={styles.contenedorInputNumero}
              onSubmit={handleSubmit(onSubmitFormulario)}
            >
              <input
                {...register("numeroTelefono", {
                  required: "Número es requerido",
                  pattern: /^[0-9]+$/, // Expresión regular para aceptar solo números
                })}
                className={styles.btnForm1}
                type="tel"
                placeholder="Teléfono nuevo: 0000000"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
              {errors.numeroTelefono && (
                <span className={styles.errors}>
                  {errors.numeroTelefono.message}
                </span>
              )}
              <button type="submit" className={styles.botonEnviar}>
                {loading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </Spinner>
                ) : (
                  "Enviar"
                )}
              </button>
            </form>
          )}

          {botonSeleccionado === 2 && (
            <form
              className={styles.contenedorInputNumero}
              onSubmit={handleSubmit(onSubmitFormulario)}
            >
              <input
                {...register("direccion", {
                  required: "La dirección es requerida",
                  pattern: {
                    value: /^[0-9a-zA-Z\s]+$/i,
                    message: "La dirección solo puede contener letras, números y espacios",
                  },
                })}
                className={styles.btnForm1}
                type="text"
                placeholder="Dirección nueva:"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9a-zA-Z\s]/g, "");
                }}
              />
              {errors.direccion && (
                <span className={styles.errors}>
                  {errors.direccion.message}
                </span>
              )}

              <div className={styles.form}>
                <select
                  {...register("departamento", {
                    required: "Departamento es requerido",
                  })}
                  className={styles.input}
                >
                  <option value="">Seleccione un departamento</option>
                  {departamentos.departamentos.map((dep, index) => (
                    <option key={index} value={dep.nombre}>
                      {dep.nombre}
                    </option>
                  ))}
                </select>
                {errors.departamento && (
                  <span className={styles.errors}>
                    {errors.departamento.message}
                  </span>
                )}
              </div>

              <div className={styles.form}>
                <select
                  {...register("municipio", {
                    required: "Municipio es requerido",
                  })}
                  className={styles.input}
                  disabled={!departamentoSeleccionado}
                >
                  <option value="">Seleccione un municipio</option>
                  {municipiosFiltrados.map((municipio, index) => (
                    <option key={index} value={municipio}>
                      {municipio}
                    </option>
                  ))}
                </select>
                {errors.municipio && (
                  <span className={styles.errors}>
                    {errors.municipio.message}
                  </span>
                )}
              </div>
              <button type="submit" className={styles.botonEnviar}>
                {loading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </Spinner>
                ) : (
                  "Enviar"
                )}
              </button>
            </form>
          )}

          {botonSeleccionado === 3 && (
            <form
              className={styles.contenedorInputNumero}
              onSubmit={handleSubmit(onSubmitFormulario)}
            >
              <input
                {...register("correo", {
                  required: "Correo es requerido",
                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i, // Expresión regular para aceptar correos válidos
                })}
                className={styles.btnForm1}
                type="email"
                placeholder="Correo nuevo:"
              />
              {errors.correo && (
                <span className={styles.errors}>
                  {errors.correo.message}
                </span>
              )}
              <button type="submit" className={styles.botonEnviar}>
                {loading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </Spinner>
                ) : (
                  "Enviar"
                )}
              </button>
            </form>
          )}

          {botonSeleccionado === 4 && (
            <form
              className={styles.contenedorInputNumero}
              onSubmit={handleSubmit(onSubmitFormulario)}
            >
              <input
                {...register("numeroTelefono", {
                  required: "Número es requerido",
                  pattern: /^[0-9]+$/i, // Expresión regular para aceptar solo números
                })}
                className={styles.btnForm1}
                type="tel"
                placeholder="Teléfono nuevo: 0000000"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
              {errors.numeroTelefono && (
                <span className={styles.errors}>
                  {errors.numeroTelefono.message}
                </span>
              )}
              <input
                {...register("direccion", {
                  required: "La dirección es requerida",
                  pattern: {
                    value: /^[0-9a-zA-Z\s]+$/i,
                    message: "La dirección solo puede contener letras, números y espacios",
                  },
                })}
                className={styles.btnForm1}
                type="text"
                placeholder="Dirección nueva:"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9a-zA-Z\s]/g, "");
                }}
              />
              {errors.direccion && (
                <span className={styles.errors}>
                  {errors.direccion.message}
                </span>
              )}
              <div className={styles.form}>
                <select
                  {...register("departamento", {
                    required: "Departamento es requerido",
                  })}
                  className={styles.input}
                >
                  <option value="">Seleccione un departamento</option>
                  {departamentos.departamentos.map((dep, index) => (
                    <option key={index} value={dep.nombre}>
                      {dep.nombre}
                    </option>
                  ))}
                </select>
                {errors.departamento && (
                  <span className={styles.errors}>
                    {errors.departamento.message}
                  </span>
                )}
              </div>

              <div className={styles.form}>
                <select
                  {...register("municipio", {
                    required: "Municipio es requerido",
                  })}
                  className={styles.input}
                  disabled={!departamentoSeleccionado}
                >
                  <option value="">Seleccione un municipio</option>
                  {municipiosFiltrados.map((municipio, index) => (
                    <option key={index} value={municipio}>
                      {municipio}
                    </option>
                  ))}
                </select>
                {errors.municipio && (
                  <span className={styles.errors}>
                    {errors.municipio.message}
                  </span>
                )}
              </div>
              <input
                {...register("correo", {
                  required: "Correo es requerido",
                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i, // Expresión regular para aceptar correos válidos
                })}
                className={styles.btnForm1}
                type="email"
                placeholder="Correo nuevo:"
              />
              {errors.correo && (
                <span className={styles.errors}>
                  {errors.correo.message}
                </span>
              )}
              <button type="submit" className={styles.botonEnviar}>
                {loading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </Spinner>
                ) : (
                  "Enviar"
                )}
              </button>
            </form>
          )}
        </div>
      )}

      {thankYouPopupOpen && (
        <div className={styles.popup}>
          <svg
            onClick={handleClosePopup}
            className={styles.cerrarMensajeConfirmacion} width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Menu / Close_LG">
              <path id="Vector" d="M21 21L12 12M12 12L3 3M12 12L21.0001 3M12 12L3 21.0001" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
          </svg>
          <div className={styles.popupInner2}>
            <FaCheckCircle color="green" size={50} />
            <h2 className={styles.tituloGracias}>Gracias.</h2>
            <p className={styles.parrafoRespuesta}>
              En un lapso de 3 días, nuestro equipo te enviará un mensaje de
              texto cuando tus datos estén actualizados en la página.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
