import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import styles from "../../../css/ProductosRelacionados.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";





export const ProductosRelacionados = ({ data, titulo = "relacionados" }) => {
  const [respuesta, setRespuesta] = useState(null);
  const prevMarcaDescripcion = useRef(null);
  const { isAuthenticated, userType, token, user } = useSelector(
    (state) => state.auth
  );

  const getData = async () => {
    try {
      if (data.marca_descripcion !== prevMarcaDescripcion.current) {
        prevMarcaDescripcion.current = data.marca_descripcion;

        // Obtener la palabra clave adecuada
        let keyword = data.lookbook ? data.lookbook.lookbook_keywords.split(' ')[0] : data.keywords.split(' ')[0];

        let response = await axios.get(
          `${process.env.REACT_APP_API_URL}/productos?page=1&pageSize=10&query=${keyword}`
        );
        setRespuesta(response.data.detalle || []);
      }
    } catch (error) {
      console.error("Error cargando los datos de productos relacionados", error);
      setRespuesta([]);
    }
  };


  useEffect(() => {
    const timeout = setTimeout(() => {
      getData();
    }, 1000); // 1000 ms = 1 segundo

    // Limpieza del timeout en caso de que cambie la dependencia o se desmonte el componente
    return () => clearTimeout(timeout);
  }, [data.marca_descripcion]);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const settings = {
    dots: false,
    infinite: respuesta && respuesta.length > 4,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const sliderRef = React.createRef();

  const goToPrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const goToNextSlide = () => {
    sliderRef.current.slickNext();
  };

  if (respuesta === null) {
    return <p className={styles.cargandoo}>Cargando...</p>;
  }

  if (respuesta.length === 0) {
    return (
      <p className={styles.cargandoo}>
        No se encontraron productos relacionados.
      </p>
    );
  }

  return (
    <>
      <h3 className={styles.titulo}>Productos {titulo}</h3>
      <div className={styles.carouselContainer}>
        <button className={styles.navButton} onClick={goToPrevSlide}>
          &larr;
        </button>
        <Slider ref={sliderRef} {...settings}>
          {respuesta.map((producto, index) => (
            <div
              key={index}
              className={`${styles.contenedor} ${styles.centeredCard}`}
            >
              <div className={styles.card}>
                <div className={styles.contenedorImg}>
                  <div className={styles.img}>
                    <Link
                      onClick={handleScrollToTop}
                      to={`/detalle/${producto.producto_id}`}
                      state={{ data: { ...producto } }}
                    >
                      <img
                        className={styles.imagen}
                        src={`${process.env.REACT_APP_URL_IMG}/${producto.imagen[0]}`}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className={styles.column}>
                    <p className={styles.titulop}>
                      {producto.descripcion_prod}
                    </p>
                    <p className={styles.precio}>${userType === "CON" ? producto.precio_CON : producto.precio_DIS}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <button className={styles.navButton} onClick={goToNextSlide}>
          &rarr;
        </button>
      </div>
    </>
  );
};
