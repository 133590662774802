import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./PagoDistribuidor.module.css";

export const PagoDistribuidor = ({ total, handleScrollToTop, handleClose, saldo, balance }) => {
  const [cantidad, setCantidad] = useState("");
  const [pagoSeleccionado, setPagoSeleccionado] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Estado para manejar la carga
  const MAX_CANTIDAD = Number(saldo).toFixed(2);

  // console.log("saldo", MAX_CANTIDAD);

  useEffect(() => {
    // Restablece el estado de carga al montar el componente
    setIsLoading(false);
  }, []);

  const handleChange = (e) => {
    let value = e.target.value;

    // Si el valor es "0" solo, se rechaza
    if (value === "0") {
      toast.error("El valor no puede ser solo 0");
      return;
    }

    // Si el valor comienza con un 0 seguido de otros dígitos y no es decimal
    if (/^0\d+/.test(value)) {
      toast.error("El valor no puede comenzar con 0");
      return;
    }

    // Solo permitir números válidos
    if (/^\d*\.?\d*$/.test(value)) {
      const parsedValue = parseFloat(value);

      // Si el valor es mayor que el máximo permitido
      if (value === "" || parsedValue <= MAX_CANTIDAD) {
        setCantidad(value);
      } else {
        toast.error(`La cantidad no puede ser mayor a ${MAX_CANTIDAD}`);
      }
    } else {
      toast.error("Ingrese un número válido");
    }
  };


  const handleSubmit = () => {
    setIsLoading(true); // Inicia la carga

    if (pagoSeleccionado === "total") {
      handleScrollToTop(saldo, "total");
      setIsLoading(false)
    } else if (pagoSeleccionado === "parcial") {
      const monto = parseFloat(cantidad);
      setIsLoading(false)
      if (cantidad === "" || monto > MAX_CANTIDAD) {
        toast.error(`La cantidad debe ser un número válido y no mayor a ${MAX_CANTIDAD}`);
        setIsLoading(false); // Finaliza la carga si hay un error
      } else if (monto > saldo) {
        toast.error(`La cantidad no puede ser mayor al total a pagar (${saldo})`);
        setIsLoading(false); // Finaliza la carga si hay un error
      } else {
        setIsLoading(false)
        handleScrollToTop(monto, "parcial");
      }
    }
  };

  const handleCloseClick = () => {
    setIsLoading(false); // Restablece el estado de carga al cerrar
    handleClose(); // Llama a la función handleClose pasada como prop
  };

  return (
    <div className={styles.containerMain}>
      <div className={styles.contenedor}>
        <button onClick={handleCloseClick} className={styles.closeButton}>
          X
        </button>
        <h2 className={styles.titulo}>
          ${MAX_CANTIDAD} <br />
          <p className={styles.parrafo}>Saldo pendiente a pagar</p>
        </h2>
        <div className={styles.lineWhite} />
        <div className={styles.checkboxContainer}>
          <label className={styles.labels}>
            <input
              className={styles.inputs}
              type="radio"
              name="pago"
              value="total"
              checked={pagoSeleccionado === "total"}
              onChange={() => setPagoSeleccionado("total")}
            />
            Pago total
          </label>
          <label className={styles.labels}>
            <input
              className={styles.inputs}
              type="radio"
              name="pago"
              value="parcial"
              checked={pagoSeleccionado === "parcial"}
              onChange={() => setPagoSeleccionado("parcial")}
            />
            Pago parcial
          </label>
        </div>

        {pagoSeleccionado === "parcial" && (
          <input
            type="text"
            placeholder="Ingrese la cantidad"
            className={styles.input}
            value={cantidad}
            onChange={handleChange}
            pattern="\d*"
          />
        )}

        {pagoSeleccionado && (
          isLoading ? (
            <div className={styles.spinner}></div> // Aquí iría tu spinner de carga
          ) : (
            <button onClick={handleSubmit} className={styles.finalizarCompra}>
              Finalizar pago
            </button>
          )
        )}
      </div>
    </div>
  );
};
