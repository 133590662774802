import { Link } from "react-router-dom";
import { Card } from "../Cards/Card";


export const InstitutoEspañol = ({ dataMarca117 }) => {

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Desplaza suavemente hacia arriba
  };


  return (
    <>
      <h2 className="titulo-español">
        Descubre los mejores productos <br />
        <strong>de Instituto Español</strong>
      </h2>
      <div className="contenedor-btn-español">
        <Link
          onClick={handleScrollToTop}
          className="español-btn"
          to={"/ver-todo-productos"}
        >
          Ver todos los productos
        </Link>
      </div>
      <div className="contenedor-cards-español">
        {dataMarca117 === "No hay datos" || !dataMarca117
          ? <p style={{ color: "#dd8093", fontSize: "30px", marginTop: "2rem" }}>Cargando...</p>
          : dataMarca117.map((ele, index) => (
            <Card
              key={index}
              data={ele}

            />
          ))}
      </div>
    </>
  );
};
