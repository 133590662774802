import React, { useEffect, useState } from "react";
import { ProductosRelacionados } from "./ProductosRelacionados";
import { Table } from "../../../homeComun/components/TablaProducto/Table";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Cargando } from "../../../homeComun/components/Cargando/Cargando"; // Asegúrate de importar el componente de carga
import { useBorrador } from "../../../state/context/borradorContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "../../../store/slices/authSlice";


export const Carrito = () => {
  const { isAuthenticated, token, user, userType } = useSelector(
    (state) => state.auth
  );
  const { borrador, loadingBorrador } = useBorrador();

  const [pedidos, setPedidos] = useState([]);
  const [carritoLocal, setCarritoLocal] = useState([]);
  const [loading, setLoading] = useState(false); // Estado de carga
  const [firstLoad, setFirstLoad] = useState(true); // Estado para controlar la primera carga
  const [cargandoCarrito, setCargandoCarrito] = useState(false);
  const navigate = useNavigate();
  const isDistributor = userType === "DIS";
  const [dataAllPedido, setDataAllPedido] = useState([]);

  // Función para obtener pedidos de la API si el usuario está autenticado



  const dispatch = useDispatch();


  const apiGet = async () => {


    const handleLogout = () => {
      dispatch(logout());
      navigate("/rol-registro");
    };

    if (isAuthenticated) {
      setLoading(true); // Activar estado de carga
      setCargandoCarrito(true);

      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/pedidos/user/${isDistributor ? user?.cliente_id : user?.email}`,
          {
            headers: {
              Authorization: `Bearer ${token ?? ""}`,
            },
          }
        );
        // console.log("este es en el carrito", res.data);


        setDataAllPedido(res?.data)
        setPedidos(
          res.data?.map((item) => {
            return {
              ...item,
              carrito: item.carrito?.map((el) => {
                return {
                  ...el,
                  idPedido: item._id,
                };
              }),
            };
          })
        );
      } catch (error) {
        if (
          error.response?.status === 400 &&
          error.response?.data?.response === "Token is invalid"
        ) {
          toast.error("Su sesión ha expirado. Por favor, vuelva a iniciar sesión.");
          handleLogout();
        } else {
          console.error("Error al obtener los pedidos:", error);
        }
      } finally {
        setLoading(false); // Desactivar estado de carga
        setFirstLoad(false); // Marcar la primera carga como completa
        setCargandoCarrito(false);
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      apiGet();
    }
  }, []);

  // Efecto para consultar localStorage si no está autenticado o cuando se carga el componente
  useEffect(() => {
    if (!isAuthenticated) {
      const carrito = JSON.parse(localStorage.getItem("carrito")) || [];
      setCarritoLocal(carrito);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (borrador) {
      navigate("/pago-pendiente");
    }
  }, [borrador, navigate]);

  return (
    <div style={{ overflow: "hidden" }}>
      {(loading && firstLoad) || loadingBorrador ? ( // Si está autenticado, cargando y es la primera carga
        <Cargando />
      ) : (
        <>
          <Table
            titulo={"carrito"}
            carrito={isAuthenticated ? pedidos[0]?.carrito || [] : carritoLocal}
            idPedido={isAuthenticated ? pedidos[0]?._id : null}
            onCarrito={apiGet} // Llama apiGet solo si es necesario
            cargandoCarrito={cargandoCarrito} // Pasar estado de carga del carrito
            setCargandoCarrito={setCargandoCarrito}
            dataAllPedido={dataAllPedido}
          />
          {/* Mostrar productos relacionados solo si hay carrito en localStorage o pedidos */}
          {isAuthenticated && pedidos[0]?.carrito[0] ? (
            <ProductosRelacionados
              titulo="recomendados"
              data={!isAuthenticated ? carritoLocal[0] : pedidos[0]?.carrito[0]}
            />
          ) : null}
        </>
      )}
    </div>
  );
};
