import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BannerProductoFavorito } from "../Baners/BannerProductoFavorito";
import { Card } from "../Cards/Card";
import styles from "../Baners/Banner.module.css";

export const ProductosFavoritos = ({ productosOfertas }) => {
  const [data, setData] = useState([]);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Desplaza suavemente hacia arriba
  };

  const getData = async () => {
    if (Array.isArray(productosOfertas) && productosOfertas.length > 0) {
      setData(productosOfertas.slice(0, 3));
    } else {
      console.warn("productosOfertas no es un arreglo o está vacío.");
    }
  };

  useEffect(() => {
    getData();
  }, [productosOfertas]);

  return (
    <>
      <h2 className="title-productosFavoritos">Las mejores ofertas</h2>
      <div className="contenedor-btn">
        <Link
          onClick={handleScrollToTop}
          className="link-Titulo"
          to={"/oferta"}
        >
          Ver todos los productos
        </Link>
      </div>
      {/* contenedor de las cards y el baner */}
      <div className={styles.contenedor}>
        {data.length > 0 ? (
          data.map((ele, index) => (
            <Card
              key={index}
              data={ele}
            />
          ))
        ) : (
          <h2 className={styles.cargando2}>Cargando...</h2>
        )}
        <BannerProductoFavorito />
      </div>
    </>
  );
};
