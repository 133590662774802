import React, { useEffect, useState } from "react";
import { Sliders } from "../components/Slider/Slider";
import { ProductoNuevo } from "../components/ProductoNuevos/ProductoNuevo";
import { ProductosFavoritos } from "../components/ProductosFavoritos/ProductosFavoritos";
import { BannerDistribuidora } from "../components/Baners/BannerDistribuidora";
import { ComoUtilizarProductos } from "../components/ComoUtilizarProductos/ComoUtilizarProductos";
import { Baners } from "../components/Baners/Baners";
import { InstitutoEspañol } from "../components/InstitutoEspañol/InstitutoEspañol";
import { Blog } from "../components/Blog/Blog";
import { Empresas } from "../components/Empresas/Empresas";
import styles from "../../css/BannerProductosNuevos.module.css";
import { Card } from "../components/Cards/Card";
import { Cargando } from "../components/Cargando/Cargando";
import axios from "axios";
import { useSelector } from "react-redux";
import { MensajeWhasapps } from "../components/MensajeFlotant/MensajeWhasapps";
import banner from "../../assets/img/Banner/image.png";
import { Link } from "react-router-dom";
import { ChatComponent } from "../components/MensajeFlotant/MensajeFlotante";
import { Contruccion } from "../components/Construccion/Contruccion";


export const HomePage = () => {
  const { isAuthenticated, token, user, userType } = useSelector((state) => state.auth);

  const [productos, setProductos] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [productosFiltrados, setProductosFiltrados] = useState([]);
  const [productosOfertas, setProductosOfertas] = useState([])
  const [dataMarca117, setDataMarca117] = useState([])


  const getData = async () => {
    try {
      // Realizar las peticiones simultáneamente para optimizar el tiempo
      const [response, responseMarca117] = await Promise.all([
        axios.get(
          `${process.env.REACT_APP_API_URL}/productos/promociones`
        ),
        axios.get(
          `${process.env.REACT_APP_API_URL}/productos/marca?id=117&page=1&pageSize=4`
        ),
      ]);

      // Procesar datos de las respuestas
      const productosFiltrados = response.data.detalle.filter(
        (producto) => producto.tipo_incentivo_id === "n"
      );

      const productosFiltradosOfertas = response.data.detalle.filter(
        (producto) => producto.tipo_incentivo_id === "p"
      );

      // Actualizar estados
      setProductos(productosFiltrados.slice(0, 3));
      setProductosOfertas(productosFiltradosOfertas);
      setDataMarca117(responseMarca117.data.detalle);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    } finally {
      // Finalizar estado de carga
      setCargando(false);
    }
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      getData();
    }, 500); // Ejecuta después de 500 ms

    // Limpieza del timeout si el componente se desmonta antes de que se complete
    return () => clearTimeout(timeout);
  }, [])

  const filtrarProductos = (productos) => {
    if (isAuthenticated && userType === "CON") {
      const filtrados = productos.filter((producto) => producto.marca_id !== "118");
      setProductosFiltrados(filtrados);
      return filtrados;
    }
    setProductosFiltrados(productos);
    return productos;
  };





  useEffect(() => {
    if (Array.isArray(productos)) {
      filtrarProductos(productos);
    }
  }, [productos, isAuthenticated, userType]);

  if (cargando) {
    return <Cargando />;
  }

  return (
    <>
      <Sliders />
      <ChatComponent />
      <MensajeWhasapps />
      {/* <ProductoNuevo /> */}
      <div className={styles.contenedorGenerals}>
        {/* Cards */}
        {/* <div className={styles.conenedorCards}>
          {productosFiltrados.length > 0 ? (
            productosFiltrados.map((ele, index) => (
              <Card
                key={index}
                data={ele}
                detalle={true}

              />
            ))
          ) : (
            <h1> Productos no disponibles momentaneamente </h1>
          )}
        </div> */}
        <Contruccion />
      </div>

      {/* <div className={styles.contenedorSVG}>
        <img src={banner} alt="" className={styles.bannerCatalogo} />
        <Link to={"/catalogo"} className={styles.contenedorBtnsCatalogo}>
          Ver catálogo
        </Link>
      </div>
      <ProductosFavoritos productosOfertas={productosOfertas} />
      <BannerDistribuidora />
      <ComoUtilizarProductos />
      <Baners />
      <InstitutoEspañol dataMarca117={dataMarca117} />
      <Blog />
      <Empresas /> */}
    </>
  );
};
