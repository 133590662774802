import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Card } from "../Cards/Card";
import styles from "./SubLineas.module.css";
import { Paginacion } from "../Pagination/Paginacion";
import { useSelector } from "react-redux";
import { Cargando } from "../Cargando/Cargando";
import { InfoNotAvailable } from "../../../components/InfoNotAvailable";

export const SubLineas = () => {
  const { isAuthenticated, token, user, userType } = useSelector((state) => state.auth);
  const { categoria, sublineaId } = useParams();  // Obtener categoría y sublineaId de los parámetros de la URL
  const [productos, setProductos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pedidos, setPedidos] = useState([]);

  const categorias = {
    "Higiene y cuidado de la piel": "05",
    "Capilar": "01",
    "Maquillaje": "06",
    "Facial": "04",
    "Perfumería": "02"
  };

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);

      try {
        const page = 1; // Solo se solicitará la primera página
        const pageSize = 1000; // Se solicitarán 1000 productos
        const categoryId = categorias[categoria]; // Obtener el valor de la categoría del objeto categorias
        let url = `${process.env.REACT_APP_API_URL}/productos/categoria?id=${categoryId}&page=${page}&pageSize=${pageSize}`;
        const response = await axios.get(url);

        if (response.data && response.data.detalle) {
          // Filtrar los productos por sublineaId
          const filteredProductos = response.data.detalle.filter(
            (producto) => producto.sublinea_id === sublineaId
          );
          setProductos(filteredProductos);
        } else {
          console.error(
            "La respuesta de la API no contiene los datos esperados:",
            response.data
          );
          setProductos([]); // Limpiar productos en caso de una respuesta no válida
        }
      } catch (error) {
        console.error("Error al cargar los datos:", error);
        setProductos([]); // En caso de error, limpia los productos
      } finally {
        setIsLoading(false);
      }
    };

    if (categoria && sublineaId) {
      // Ejecuta getData solo si `categoria` y `sublineaId` tienen valores válidos
      getData();
    }
  }, []);




  if (isLoading) {
    return <Cargando />;
  }

  return (
    <>
      <div className={styles.contenedor}>
        {productos.length > 0 ? (
          productos.map((elem, index) => (
            <Card
              key={index}
              data={elem}
              agregar="pedido"
              pedidos={pedidos}

            />
          ))
        ) : (
          <InfoNotAvailable message="No hay productos disponibles" />
        )}
      </div>
    </>
  );
};
