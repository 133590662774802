import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { logout } from "../../src/store/slices/authSlice";

export const useGetBorrador = () => {
  const { token, user, userType } = useSelector((state) => state.auth);
  const [borrador, setBorrador] = useState(null);
  const [loadingBorrador, setLoadingBorrador] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDistributor = userType === "DIS";

  const handleGetData = async () => {
    const handleLogout = () => {
      dispatch(logout());
      navigate("/rol-registro");
    };

    try {
      const resPedido = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos/user/${
          isDistributor ? user.cliente_id : user.email
        }`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );
      return resPedido?.data?.[0].carrito?.[0].idPedido;
    } catch (error) {
      if (
        error.response?.status === 400 &&
        error.response?.data?.response === "Token is invalid"
      ) {
        toast.error(
          "Su sesión ha expirado. Por favor, vuelva a iniciar sesión."
        );
        handleLogout();
      } else {
        console.error("Error al obtener los datos:", error);
      }
    }
  };

  const getBorrador = async (tokenDefault) => {
    setLoadingBorrador(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/borrador`,
        {
          headers: {
            Authorization: `Bearer ${(tokenDefault || token) ?? ""}`,
          },
        }
      );

      const idPedidoDataUser = await handleGetData();

      let resActualizarBorrador = await axios.post(
        `${process.env.REACT_APP_API_URL}/pedidos/actualizar/borrador`,
        {
          tipo: userType,
          idPedido: idPedidoDataUser,
          cookie:
            response?.data?.borrador?.detalle?.PHPSESSID ||
            response?.data?.borrador.PHPSESSID,
          detalle:
            response?.data?.borrador?.detalle || response?.data?.borrador,
        },
        {
          headers: {
            Authorization: `Bearer ${(tokenDefault || token) ?? ""}`,
          },
        }
      );

      setBorrador(resActualizarBorrador?.data?.detalle);
      return resActualizarBorrador?.data?.detalle;
    } catch (error) {
      setBorrador(null);
    } finally {
      setLoadingBorrador(false);
    }
  };

  useEffect(() => {
    if (token) {
      const timer = setTimeout(() => {
        getBorrador();
      }, 1000); // 1000 ms = 1 segundo

      return () => clearTimeout(timer); // Limpia el timeout si el efecto se vuelve a ejecutar
    }
  }, [token]);

  return {
    borrador,
    getBorrador,
    setBorrador,
    loadingBorrador,
  };
};
