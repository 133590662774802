import styles from "./BotonCompra.module.css";
import { Link } from 'react-router-dom';

export const BotonCompra = ({ text, path, estilos }) => {
    if (!path) {
        return null; // No renderizar nada si path está vacío
    }

    // Determina la ruta dinámica basada en el texto del botón
    let ruta = path;

// console.log(estilos[0]);

    return (
        <Link
            to={ruta}

            className={`${styles[estilos[0]]}`}
        >
            <button className={styles[estilos[1]]}>
                {text || "Ir"}
            </button>
        </Link>
    );
};
