// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Contruccion_contenedor__ByLK\\+ {\n  position: relative;\n  text-align: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 20px;\n  background-color: #dd8093;\n  color: #fff;\n  border-radius: 20px;\n  height: 400px;\n  width: 400px;\n  min-width: 150px !important;\n  min-height: 80px !important;\n  font-weight: 700;\n  flex-direction: column;\n  margin-bottom: 20px;\n  margin-top: 20px;\n}\n.Contruccion_texto__HZkFM {\n  color: #fff;\n  font-size: 25px;\n  text-align: center;\n  font-family: \"Lato\";\n  line-height: 30px;\n}\n.Contruccion_strongs__u1\\+mq {\n  font-family: \"Lato\";\n  font-size: 25px;\n}\n", "",{"version":3,"sources":["webpack://./src/homeComun/components/Construccion/Contruccion.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,WAAW;EACX,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,2BAA2B;EAC3B,2BAA2B;EAC3B,gBAAgB;EAChB,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".contenedor {\n  position: relative;\n  text-align: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 20px;\n  background-color: #dd8093;\n  color: #fff;\n  border-radius: 20px;\n  height: 400px;\n  width: 400px;\n  min-width: 150px !important;\n  min-height: 80px !important;\n  font-weight: 700;\n  flex-direction: column;\n  margin-bottom: 20px;\n  margin-top: 20px;\n}\n.texto {\n  color: #fff;\n  font-size: 25px;\n  text-align: center;\n  font-family: \"Lato\";\n  line-height: 30px;\n}\n.strongs {\n  font-family: \"Lato\";\n  font-size: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contenedor": "Contruccion_contenedor__ByLK+",
	"texto": "Contruccion_texto__HZkFM",
	"strongs": "Contruccion_strongs__u1+mq"
};
export default ___CSS_LOADER_EXPORT___;
