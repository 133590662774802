import React, { useEffect } from "react";
import styles from "../../../css/InformacionPago.module.css";
import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ProductsTotal } from "../../../components/homeCliente/components/ProductsTotal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { PopupPaymentOption } from "./PopupPaymentOption";
import { Awards } from "../Awards";
import FormData from "form-data";
import { useBorrador } from "../../../state/context/borradorContext";
import { redondearDecimales } from "../../../hooks/use-redondearDecimales";


export const InformacionPagoDistribuidor = () => {
  const { isAuthenticated, userType, token, user } = useSelector(
    (state) => state.auth
  );
  const isDistributor = userType === "DIS";
  const [esqueleto, setEsqueleto] = useState()
  const [serfinsa, setSerfinsa] = useState(null);
  const [showPopupPay, setShowPopupPay] = useState(false);
  const [seleccionado, setSeleccionado] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingGetData, setLoadingGetData] = useState(false);
  const [viewBuyButton, setViewBuyButton] = useState(false);
  const [dataUser, setdataUser] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [deliveryData, setDeliveryData] = useState();
  const { borrador, setBorrador } = useBorrador();

  // console.log({ seleccionado });

  const [showPopupAwards, setShowPopupAwards] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const [valueSelected, setValueSelected] = useState({
    value: "",
    price: "",
  });
  // console.log({ valueSelected });
  const [borradorVogue, setBorradorVogue] = useState()
  const [dateUserDefault, setDateUserDefault] = useState({
    id: "",
    nombre: "",
    apellido: "",
    direccion: "",
  });
  const navigate = useNavigate();
  const [newDataAddres, setNewDataAddres] = useState({
    nombre: "",
    apellido: "",
    direccion: "",
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const [finalPurchaseData, setFinalPurchaseData] = useState(null);
  const [awardSaved, setAwardSaved] = useState([
    {
      tipo_incentivo_id: "No deseo agregar premio",
      producto_id: "0",
      campania_id: "0",
      descripcion: "NO DESEO PREMIO",
    },
  ]);

  const [ssid, setSsid] = useState("");
  const location = useLocation();

  const { products } = location.state;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      nombre: dataUser?.customer?.name || "",
      apellido: dataUser?.customer?.apellido || "",
      direccion: dataUser?.shippingOption?.address || "",
    },
  });

  useEffect(() => {
    handleGetData();
  }, [valueSelected]);

  const handleScrollToTop = async () => {
    setLoading(true);

    try {
      if (isAuthenticated) {
        let montoTotal = parseFloat(borradorVogue) + parseFloat(valueSelected.price)
        let res = await axios.post(
          `${process.env.REACT_APP_API_URL}/create-payment`,
          {
            pedidoId: serfinsa._id,
            Monto: redondearDecimales(montoTotal, 2),
            ConceptoPago: "Pago de cosmeticos",
            Adicionales: "DATOS ADICIONALES",
            UrlOrigin:
              "https://vogueelsalvador.com/home-cliente/informacio-pago",
            UrlRedirect: `https://vogueelsalvador.com/finalizar-comprass`,

          },
          {
            headers: {
              Authorization: `Bearer ${token ?? ""}`,
            },
          }
        );
        const { Satisfactorio, Datos, Mensaje, Error } = res.data;
        if (Satisfactorio) {
          window.location.href = `https://www.serfinsacheckout.com/${Datos?.UrlPost}`;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const totalPrecioProductos = products?.reduce(
    (total, product) => total + product.price * product.quantity,
    0
  );

  const priceTotal = redondearDecimales((Number(totalPrecioProductos) + Number(valueSelected.price)), 2);

  // console.log(priceTotal);


  const handleGetData = async () => {
    setLoadingGetData(true);
    try {
      const resPedido = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos/user/${user.cliente_id}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );
      if (!resPedido?.data?.length) {
        navigate("/");
      }
      setSerfinsa({
        _id: resPedido?.data[0]._id,
        monto: priceTotal,
      });
      setdataUser(resPedido?.data?.[0]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGetData(false);
      setShowContent(true);
    }
  };

  const handleEdit = async (data) => {
    try {
      const dataUpdated = await axios.put(
        `${process.env.REACT_APP_API_URL}/pedidos/update-info`,
        {
          name: data.nombre,
          apellido: data.apellido,
          address: data.direccion,
          codigoAdmon: data?.codigoAdmon || valueSelected.value,
          idPedido: dataUser?.carrito?.[0].idPedido,
          totalEnvio:
            data?.codigoAdmon === "CARADMI011" ? 0.01 : valueSelected.price,
        },
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );
      setIsDisabled(true);
      if (dataUpdated.status === 200 || dataUpdated.status === 201) {
        toast.success("Datos actualizados correctamente");
        setViewBuyButton(true);
        setOpenForm(false);
        reset();
      } else {
        toast.error("No se pudo actualizar los datos.");
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Error en el servidor. Intentelo nuevamente");
      } else if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          "Ocurrió un error al enviar los datos. Por favor, inténtalo de nuevo más tarde."
        );
      }
    }
  };

  const getPedidos = async () => {
    const username =
      "c2ac07cafartwetsdADS2356FEDGeiE7xz7Gnn3XcxEkAQlPxtRIqf2gFu7q";
    const password =
      "c2ac07cafartwetsdADS2356FEDGe3LZNmqXlF8NgGg83fp4RjvUdzxswwmO";
    const authHeader = "Basic " + btoa(username + ":" + password);
    try {
      const resPedido = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos/cargos/admon?email=${user.cliente_id}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );

      setDeliveryData(resPedido?.data?.detalle);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveData = (value) => {
    setNewDataAddres({
      nombre: value.nombre,
      apellido: value.apellido,
      direccion: value.direccion,
    });
    setDateUserDefault({
      nombre: value.nombre,
      apellido: value.apellido,
      direccion: value.direccion,
    });
    setOpenForm(false);
  };

  const handleValue = (e) => {
    setValueSelected({
      value: e.target.value,
      price: e.target.id === "recogerEnTienda" ? "0.01" : e.target.id,
    });
  };

  const handleConfirmPurchase = async () => {
    localStorage.removeItem("satisFactorio");
    // localStorage.setItem("loading", false);
    setLoading(true);
    const url = "https://mivogue.com:83/APIs";
    const username =
      "c2ac07cafartwetsdADS2356FEDGeiE7xz7Gnn3XcxEkAQlPxtRIqf2gFu7q";
    const password =
      "c2ac07cafartwetsdADS2356FEDGe3LZNmqXlF8NgGg83fp4RjvUdzxswwmO";
    const authHeader = "Basic " + btoa(username + ":" + password);

    try {
      const idPedido = dataUser?.carrito?.[0].idPedido;
      const resPreData = await axios.post(
        `${process.env.REACT_APP_API_URL}/pedidos/pre-data/borrador`,
        {
          idPedido,
          tipo: "DIS",
        },
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );
      if (resPreData.status === 200 || resPreData.status === 201) {
        const formData = new FormData();
        formData.append("proceso", resPreData.data.proceso);
        formData.append("contenido", JSON.stringify(resPreData.data.items));

        const headers = {
          Authorization: authHeader,
          "Content-Type": "multipart/form-data",
        };

        let res = await axios.post(
          `${url}/pedidos/${user.cliente_id}`,
          formData,
          {
            headers: headers,
          }
        );
        if (res?.data?.success) {
          setBorradorVogue(res.data.detalle.total)
          const resCookie = await axios.get(
            `${url}/administrativos/${user.cliente_id}`,
            {
              headers: {
                Authorization: authHeader,
              },
              withCredentials: true,
            }
          );

          // console.log("1", res?.data?.detalle);
          // console.log("2", res?.data);

          let resBorrador = await axios.post(
            `${process.env.REACT_APP_API_URL}/borrador`,
            {
              borrador: res?.data?.detalle,
              idPedido,
            },
            {
              headers: {
                Authorization: `Bearer ${token ?? ""}`,
              },
            }
          );
          toast.success("Pedido confirmado con éxito");
          setEsqueleto(false)
          setShowPopupAwards(true);
          setFinalPurchaseData(res.data);
          if (resCookie?.data?.success) {
            setSsid(res.data.detalle.PHPSESSID);

            if (resBorrador.status === 200 || resBorrador.status === 201) {
              const resActualizarBorrador = await axios.post(
                `${process.env.REACT_APP_API_URL}/pedidos/actualizar/borrador`,
                {
                  tipo: userType,
                  idPedido,
                  cookie:
                    res.data.detalle.PHPSESSID ||
                    resBorrador?.data?.borrador?.PHPSESSID,
                  detalle: resBorrador?.data?.borrador,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token ?? ""}`,
                  },
                }
              );
              // console.log("borrador", resBorrador?.data);
              setBorrador(resActualizarBorrador?.data?.detalle);
              setEsqueleto(false)

            }
          } else {
            toast.error("Error en el pedido");
            setEsqueleto(false)

          }
        } else {
          if (res.data.detalle) {
            const errorMessage =
              res.data.message ||
              res.data.detalle ||
              res.data.error ||
              "Ocurrió un error al confirmar el pedido.";
            toast.error(errorMessage);
            setEsqueleto(false)

          }
        }
      }
    } catch (error) {
      console.error("error", error);
      if (error.response) {
        const errorMessage =
          error.response.data.message ||
          error.response.data.detalle ||
          error.response.data.error ||
          "Ocurrió un error al confirmar el pedido.";

        toast.error(errorMessage);
        setEsqueleto(false)

      } else {
        toast.error(
          "Ocurrió un error al enviar los datos. Por favor, inténtalo de nuevo más tarde."
        );
        setEsqueleto(false)

      }
    } finally {
      setLoading(false);
      setEsqueleto(false)

    }
  };



  const handleValueAwards = async (awardSavedArray) => {
    if (awardSavedArray[0].descripcion !== "NO DESEO PREMIO") {
      const newAwards = awardSavedArray.map((a) => ({
        tipoIncentivoId: a.tipo_incentivo_id,
        quantity: a.quantity,
        itemId: a.producto_id,
        campaniaId: a.campania_id,
        idPedido: a.idPedido,
        description: a.descripcion,
      }));

      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/pedidos/incentivos/add-item`,
        newAwards,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );

      if (res.status === 200 || res.status === 201) {
        toast.success("Opción seleccionada con éxito");
      } else {
        toast.error("No se pudo seleccionar la opción.");
      }
    } else {
      toast.success("Opción seleccionada con éxito");
    }
  };

  const handleContinue = async (productsIdArray) => {
    try {
      await handleValueAwards(productsIdArray);
      await handleGetData();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPedidos();
  }, []);

  useEffect(() => {
    if (borrador) {
      const handlePopState = (event) => {
        navigate("/", { replace: true });
      };

      window.addEventListener("popstate", handlePopState);
    }
  }, [navigate]);

  return (
    <div style={{ position: "relative" }}>
      <div className={styles.cajaContenedor}>
        {esqueleto && (
          <div className={styles.overlay}>

          </div>
        )}
        <section className={styles.contenedorIzquierdo}>
          <>
            <div className={styles.entregaContenedor}>
              <h3 className={styles.TituloEntrega}>Entrega de pedido</h3>
            </div>

            {showContent ? (
              <div className={styles.sectionSelectType}>
                <section className={styles.sectionTypeofSend}>
                  <div className={styles.inputSelectForm}>
                    <div className={styles.containerCheck}>
                      <input
                        className={styles.chetRecoger}
                        type="radio"
                        value="CARADMI011"
                        id="0.01"
                        onChange={(e) => {
                          setSeleccionado("Recoger en tienda");
                          handleValue(e);
                          handleEdit({
                            nombre: user.nombres,
                            apellido: user.apellidos,
                            direccion: user.direccion,
                            codigoAdmon: "CARADMI011",
                            price: "0.01",
                          });
                        }}
                        checked={seleccionado === "Recoger en tienda"}
                      />
                    </div>
                    <div className={styles.contenedorFechaReparto}>
                      <div className={styles.separar}>
                        <label
                          htmlFor="recogerEnTienda"
                          className={styles.parrafoRecoger}
                        >
                          Recoger en tienda
                        </label>
                      </div>
                      <p className={styles.fechaRetiro}>
                        Recuerda que tienes hasta el 28 de este mes para poder
                        retirar tu pedido.
                      </p>
                    </div>
                  </div>

                  <div className={styles.inputSelectForm}>
                    <input
                      className={styles.chetRecoger}
                      type="radio"
                      value="CARADMI029"
                      id={deliveryData?.[1]?.importe}
                      onChange={(e) => {
                        setSeleccionado("Aeroflash");
                        handleValue(e);
                        setViewBuyButton(false);
                      }}
                      checked={seleccionado === "Aeroflash"}
                    />
                    <label
                      htmlFor="aeroflash"
                      className={styles.parrafoRecoger}
                    >
                      Aeroflash
                    </label>
                  </div>

                  {deliveryData?.[2] && (
                    <div className={styles.inputSelectForm}>
                      <input
                        className={styles.chetRecoger}
                        type="radio"
                        value="CARADMI016"
                        id={deliveryData?.[2]?.importe}
                        checked={seleccionado === "Motoexpress"}
                        onChange={(e) => {
                          setSeleccionado("Motoexpress");
                          handleValue(e);
                          // getPedidos();
                        }}
                      />
                      <label
                        htmlFor="Motoenvio"
                        className={styles.parrafoRecoger}
                      >
                        Motoexpress
                      </label>
                    </div>
                  )}

                  {deliveryData?.[3] && (
                    <div className={styles.inputSelectForm}>
                      <input
                        className={styles.chetRecoger}
                        type="radio"
                        value="CARADMI017"
                        id={deliveryData?.[3]?.importe}
                        onChange={(e) => {
                          setSeleccionado("Reparto");
                          handleValue(e);
                          // getPedidos();
                        }}
                        checked={seleccionado === "Reparto"}
                      />
                      <label
                        className={styles.parrafoRecoger}
                        htmlFor="Reparto"
                      >
                        Reparto
                      </label>
                    </div>
                  )}
                </section>
                <section className={styles.sectionTextDate}>
                  {Array.isArray(deliveryData) &&
                    deliveryData.length > 3 &&
                    deliveryData[3].fecha_entrega ? (
                    <p>{deliveryData[3].fecha_entrega}</p>
                  ) : (
                    <p>Fecha de entrega no disponible</p>
                  )}
                </section>
              </div>
            ) : (
              <h3>Cargando...</h3>
            )}

            {seleccionado !== "Recoger en tienda" && seleccionado && (
              <>
                <div className={styles.entregaContenedor}>
                  <h3 className={styles.TituloEntrega}>Dirección de envío</h3>
                </div>
                <div className={styles.contenedorDireccion}>
                  <div className={styles.contenedorCajaDirreciion}>
                    <div className={styles.containerName}>
                      <input
                        className={styles.chet}
                        type="checkbox"
                        value={user?.direccion}
                        checked={dateUserDefault.direccion === user?.direccion}
                        onChange={() => {
                          setDateUserDefault({
                            id: user?.direccion,
                            nombre: user?.nombres,
                            apellido: user?.apellidos,
                            direccion: user?.direccion,
                          });
                          setIsDisabled(false);
                          setOpenForm(false);
                        }}
                      />
                      <p className={styles.nombre}>
                        {user?.nombres + " " + user?.apellidos}
                      </p>
                    </div>
                    <p className={styles.dirreccion}>{user?.direccion}</p>
                  </div>

                  {newDataAddres?.direccion && (
                    <div className={styles.contenedorCajaDirreciion}>
                      <div className={styles.containerName}>
                        <input
                          className={styles.chet}
                          type="checkbox"
                          checked={
                            dateUserDefault.direccion ===
                            newDataAddres?.direccion
                          }
                          onChange={() => {
                            setDateUserDefault({
                              id: newDataAddres?.direccion,
                              nombre: newDataAddres?.nombre,
                              apellido: newDataAddres?.apellido,
                              direccion: newDataAddres?.direccion,
                            });
                            setIsDisabled(false);
                          }}
                        />
                        <p className={styles.nombre}>
                          {newDataAddres?.nombre +
                            " " +
                            newDataAddres?.apellido}
                        </p>
                      </div>

                      <p className={styles.dirreccion}>
                        {newDataAddres?.direccion}
                      </p>
                    </div>
                  )}

                  <div className={styles.containerButtonSearch}>
                    <button
                      className={styles.botonWhasaps}
                      onClick={() => {
                        const telefono = "+50322618181";
                        const mensaje = encodeURIComponent(
                          "Hola Vogue, deseo cambiar la dirección de entrega actual por una nueva dirección."
                        );
                        window.open(
                          `https://web.whatsapp.com/send?phone=${telefono}&text=${mensaje}`,
                          "_blank"
                        );

                        setDateUserDefault({
                          id: user?.direccion,
                          nombre: user?.nombres,
                          apellido: user?.apellidos,
                          direccion: user?.direccion,
                        });
                      }}
                    >
                      Cambiar dirección de entrega
                    </button>
                  </div>

                  {openForm && (
                    <section className={styles.containerForm}>
                      <h3 className={styles.otraDirreccion}>
                        Utilizar otra dirección
                      </h3>
                      <form onSubmit={handleSubmit(handleSaveData)}>
                        <div className={styles.formulario}>
                          <div className={styles.input}>
                            <p className={styles.nombreform}>Nombre</p>
                            <input
                              className={styles.form}
                              type="text"
                              {...register("nombre", {
                                required: "Este campo es requerido",
                              })}
                            />
                            {errors.nombre && (
                              <p className={styles.error}>
                                {errors.nombre.message}
                              </p>
                            )}
                          </div>
                          <div className={styles.input}>
                            <p className={styles.nombreform}>Apellido</p>
                            <input
                              className={styles.form}
                              type="text"
                              {...register("apellido", {
                                required: "Este campo es requerido",
                              })}
                            />
                            {errors.apellido && (
                              <p className={styles.error}>
                                {errors.apellido.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className={styles.direccionForm}>
                          <p className={styles.dirrec}>Dirección</p>
                          <input
                            className={styles.inputForm}
                            type="text"
                            {...register("direccion", {
                              required: "Este campo es requerido",
                            })}
                          />
                          {errors.direccion && (
                            <p className={styles.error}>
                              {errors.direccion.message}
                            </p>
                          )}
                        </div>
                        <button className={styles.buttonUpdate} type="submit">
                          Agregar nuevos datos
                        </button>
                      </form>
                    </section>
                  )}
                  <button
                    className={
                      dateUserDefault.direccion && !isDisabled
                        ? styles.buttonUpdate
                        : styles.buttonOff
                    }
                    disabled={!dateUserDefault.direccion || isDisabled}
                    onClick={() => handleEdit(dateUserDefault)}
                  >
                    Usar la dirección seleccionada
                  </button>
                </div>
              </>
            )}
          </>
        </section>

        <div className={styles.contenedorFinalizarProductos}>
          <div>
            <div className={styles.sectionCheckout}>
              {Array.isArray(products) && products.length > 0 ? (
                products.map((product) => (
                  <ProductsTotal key={product.nameItem} product={product} />
                ))
              ) : Array.isArray(products.detalles_factura) && products.detalles_factura?.length > 0 ? (
                products.detalles_factura.map((product) => (
                  <ProductsTotal
                    key={product.producto_id}
                    product={{
                      nameItem: product.descripcion,  // Usar descripción como nameItem
                      cantidad: product.cantidad,      // Cantidad de productos
                      subtotal: product.subtotal,      // Subtotal calculado
                      imagen: product.imagen,          // Array de imágenes
                      price: product.valor_fijo,       // Valor fijo como price
                      quantity: product.cantidad,      // Puedes mapear cantidad a quantity
                    }}
                  />
                ))
              ) : (
                <p>No hay productos disponibles.</p>
              )}
              {awardSaved &&
                awardSaved.some(
                  (a) => a.tipo_incentivo_id !== "No deseo agregar premio"
                ) &&
                awardSaved.map((a) => (
                  <>
                    <div key={a.producto_id} className={styles.awardsInSummary}>
                      <div className={styles.titleAwards}>
                        <img
                          style={{ width: "50px", marginRight: "5px" }}
                          src={`${process.env.REACT_APP_URL_IMG}/${a?.imagen[0]}`}
                          alt="imagen de ejemplo"
                        />
                        <p>{a.tipo_incentivo_id}</p>
                      </div>
                      <p>1x</p>
                    </div>
                    <div className={styles.linea} />
                  </>
                ))}
            </div>
            <div className={styles.priceForShipping}>
              <p className={styles.textSend}>Envio:</p>
              <p className={styles.priceSend}>${valueSelected.price || 0}</p>
            </div>
            <div className={styles.btnTotal}>
              <p className={styles.productoTotal}>TOTAL:</p>
              <p className={styles.monto}>${priceTotal}</p>
            </div>
          </div>
          <div className={styles.contenedorFinalaizar}>
            {viewBuyButton && !finalPurchaseData && (
              <button
                className={styles.BtnFinalizar}
                id="btPagar"
                onClick={() => {
                  setEsqueleto(true)
                  handleConfirmPurchase();
                }}
                disabled
              >
                {loading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </Spinner>
                ) : (
                  "Confirmar compra"
                )}

              </button>
            )}
          </div>
        </div>
      </div>

      {showPopupPay && (
        <PopupPaymentOption
          handleScrollToTop={handleScrollToTop}
          loading={loading}
          isDistributor={isDistributor}
          ssid={ssid}
          showContent={showContent}
        />
      )}
      {showPopupAwards && (
        <Awards
          ssid={ssid}
          token={token}
          setShowPopupAwards={setShowPopupAwards}
          user={user}
          setShowPopupPay={setShowPopupPay}
          setAwardSaved={setAwardSaved}
          // handleValueAwards={handleValueAwards}
          awardSaved={awardSaved}
          dataUser={dataUser}
          loadingGetData={loadingGetData}
          handleContinue={handleContinue}
        />
      )}
    </div>
  );
};
