import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { Card } from "../Cards/Card";
import { Paginacion } from "../Pagination/Paginacion";
import styles from "./Resultados.module.css";
import { useSelector } from "react-redux";
import { Inventario } from "../../../HomeDistribuidor/components/TiendaEnLinea/Inventario";

export const Resultado = () => {
  const { isAuthenticated, token, user, userType } = useSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [pedidos, setPedidos] = useState([]);
  const [productosFiltrados, setProductosFiltrados] = useState([]);

  const prevQueryRef = useRef(query);

  const handlePageChange = (newPage) => {
    if (!isLoading) {
      setCurrentPage(newPage);
    }
  };

  const fetchProductos = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/productos?page=${currentPage}&pageSize=9&query=${query}`
      );
      const productos = response.data.detalle;
      setTotalPages(response.data.totalPages);
      filtrarProductos(productos);
    } catch (error) {
      console.error("Error al cargar los productos:", error);
    }
    setIsLoading(false);
  };

  const filtrarProductos = (productos) => {
    if (isAuthenticated && userType === "CON") {
      const filtrados = productos.filter((producto) => producto.marca_id !== "118");
      setProductosFiltrados(filtrados);
    } else {
      setProductosFiltrados(productos);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      const currentQuery = searchParams.get("query");
      if (currentQuery !== prevQueryRef.current) {
        setCurrentPage(1);
        prevQueryRef.current = currentQuery;
        fetchProductos();
      } else if (currentPage === 1 || currentQuery === prevQueryRef.current) {
        fetchProductos();
      }
    }, 1000); // Retraso de 1 segundo (1000 milisegundos)

    // Limpiar el timeout si el componente se desmonta o el efecto se vuelve a ejecutar
    return () => clearTimeout(timeout);
  }, [query, currentPage]);




  const shouldShowPagination = totalPages > 1 && productosFiltrados.length > 0;



  return (
    <div className={styles.resultadosContenedor}>
      {isLoading ? (
        <h2 className={styles.cargandoo}>Cargando...</h2>
      )
        : productosFiltrados.length === 0 ? (
          <div className={styles.contenedorInventario}>
            <Inventario />
          </div>

        )
          : productosFiltrados.length > 0 ? (
            <div className={styles.conenedorCards}>
              {productosFiltrados.map((ele, index) => (
                <Card
                  key={index}
                  data={ele}
                  cantidadProp={userType === "DIS" ? true : false}
                  detalle={true}
                  pedidos={pedidos}

                  agregar="pedido"
                />
              ))}
            </div>
          ) : (
            <h2 className={styles.cargandoo}>No se encontraron resultados.</h2>
          )}
      {shouldShowPagination && (
        <Paginacion
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};
