import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import LogoVogue from "../../assets/svg/carritio";
import DropdownButton from "react-bootstrap/DropdownButton";
import styles from "../Css/NavDistribuidor.module.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/slices/authSlice";
import { Sidebar } from "../../homeComun/components/Sidebar/Sidebar";
import { useSidebar } from "../../hooks/use-sidebar";
import SvgSidebar from "../../assets/svg/SvgSidebar";
import SvgBuscador from "../../assets/svg/buscadorSvg";
import { CiSearch } from "react-icons/ci";
import SvgCarrito from "../../assets/svg/SvgCarrito";
import SvgCarritoResponsive from "../../assets/svg/SvgCarritoTwo";
import SvgClosedButtonSearch from "../../assets/svg/SvgClosedButtonSearch";
import { LuUserCircle2 } from "react-icons/lu";
import { FaStore } from "react-icons/fa";
import axios from "axios";
import { productos } from "../../assets/utils/Productos";
import useWindowSize from "../../homeComun/components/Nav/useWindowSize";
import { useBorrador } from "../../state/context/borradorContext";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { usePedidos } from "../../state/context/pedidosContextProvider";

export const NavDistribuidor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { pedidos } = usePedidos();
  const [query, setQuery] = useState("");
  const { showSidebar, handleClose, setShowSidebar, handleShow } = useSidebar();
  const [showSearch, setShowSearch] = useState(false);
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Desplaza suavemente hacia arriba
  };
  const [pedidosArray, setPedidosArray] = useState([]);
  const { borrador, setBorrador, loadingBorrador } = useBorrador();
  const size = useWindowSize();
  const inputRef = useRef(null); // Referencia al input
  const wrapperRef = useRef(null);
  const [allInvoice, setAllInvoice] = useState([]);

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value.length > 0) {
      const filteredSuggestions = productos.filter((product) => {
        const productKeywords = product.keywords
          .toLowerCase()
          .split(",")
          .map((kw) => kw.trim());
        return productKeywords.some((kw) => kw.includes(value.toLowerCase()));
      });
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };
  const { isAuthenticated, token, user, userType } = useSelector(
    (state) => state.auth
  );

  const handleKeyDown = (e) => {
    // Verifica si la tecla presionada es Enter
    if (e.key === "Enter") {
      buscarProductos();
      setSuggestions([]);
    }
  };

  const buscarProductos = (productoId) => {
    setSuggestions([]);
    if (productoId) {
      navigate(`/detalle/${productoId}`);
    } else {
      const productosFiltrados = productos.filter((producto) =>
        producto.descripcion_prod.toLowerCase().includes(query.toLowerCase())
      );

      const productosSimplificados = productosFiltrados.map((producto) => ({
        categoria: producto.categoria,
        descripcion_sublinea: producto.descripcion_sublinea,
        descripcion_corta: producto.descripcion_corta,
      }));

      const productosJSON = JSON.stringify(productosSimplificados);

      navigate(
        `/resultados?query=${query}&productos=${encodeURIComponent(
          productosJSON
        )}`
      );
    }
    setQuery("");
  };

  const handleClick = (product) => {
    setQuery(product.descripcion_corta);
    setSuggestions([]);
    buscarProductos(product.producto_id);
  };

  const handleBlur = (e) => {
    setTimeout(() => {
      if (!wrapperRef.current.contains(document.activeElement)) {
        setSuggestions([]);
      }
    }, 100);
  };

  const handleOutsideClick = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleLogout = (e) => {
    dispatch(logout());
    navigate("/rol-registro");
  };



  const borradorDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/borrador`, {
        headers: {
          Authorization: `Bearer ${token ?? ""}`,
        },
      });
      setBorrador(null);
      //getBorrador(); // Refetch the borrador after deleting
    } catch (error) {
      console.error(error);
    }
  };

  let idPedido = pedidosArray?.[0]?._id;

  const emptyCart = async () => {
    if (token) {
      // Si el usuario está autenticado, enviar solicitud al servidor para vaciar el carrito
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/pedidos/cancelar-pedido`,
          {
            idPedido: idPedido,
          },
          {
            headers: {
              Authorization: `Bearer ${token ?? ""}`,
            },
          }
        );
      } catch (error) {
        console.error("Error al vaciar el carrito:", error);
      }
    }
  };

  useEffect(() => {
    if (allInvoice.length > 0) {
      borradorDelete();
    }
  }, []);

  useEffect(() => {
    if (allInvoice.length > 0) {
      emptyCart();
    }
  }, []);

  const nombreUsuario = user?.nombres;
  let nombreFormateado;
  const partesNombre = nombreUsuario.split(" ");
  nombreFormateado =
    partesNombre[0].charAt(0).toUpperCase() +
    partesNombre[0].slice(1).toLowerCase();
  if (partesNombre.length > 1) {
    nombreFormateado += "...";
  }
  // console.log(user.tipo_cre);


  const formaPago = () => {
    if (userType === "DIS") {
      return user?.tipo_cre === "CONTADO" ? "Contado" : "Crédito";
    }
    return null // Opcional, por si `userType` no es "DIS"
  };

  const paymentType = formaPago();

  const handleClickCarrito = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (userType === "DIS" && formaPago() === "Crédito") {
        try {
          // Obtener el estado de pagar después y las facturas pendientes en una sola petición
          const [responseFactura, responsePagarDespues] = await Promise.all([
            axios.get(
              `${process.env.REACT_APP_API_URL}/clientes?id=${user.cliente_id}`,
              {
                headers: { Authorization: `Bearer ${token ?? ""}` },
              }
            ),
            axios.get(
              `${process.env.REACT_APP_API_URL}/pedidos/pagar-despues/${user.cliente_id}`,
              {
                headers: { Authorization: `Bearer ${token ?? ""}` },
              }
            ),
          ]);

          const facturas = responseFactura.data.detalle.lista_facturas;
          const pagarDespuesStatus = responsePagarDespues.data.status;

          if (pagarDespuesStatus) {
            const getPedidoId = await axios.get(
              `${process.env.REACT_APP_API_URL}/pedidos/pagar-despues/${user.cliente_id}`,
              {
                headers: { Authorization: `Bearer ${token ?? ""}` },
              }
            );

            const pedidoIdPagarDespues = getPedidoId.data._id;

            // Actualizar el pedido a pendiente solo si hay facturas (allInvoice.length !== 0)
            if (facturas.length !== 0) {
              await axios.put(
                `${process.env.REACT_APP_API_URL}/pedidos/update-pendiente`,
                { idPedido: pedidoIdPagarDespues },
                {
                  headers: { Authorization: `Bearer ${token ?? ""}` },
                }
              );
            }

            try {
              // Siempre intentar borrar el borrador si pagarDespuesStatus es true
              const responseBorrador = await axios.delete(
                `${process.env.REACT_APP_API_URL}/borrador`,
                {
                  headers: { Authorization: `Bearer ${token ?? ""}` },
                }
              );

              // Verificar si el borrador fue eliminado correctamente
              if (responseBorrador.data.msg === "Se elimino correctamente") {
                // Si se eliminó correctamente, redirigir al carrito
                // console.log(
                //   `Borrador eliminado para el cliente ID: ${user.cliente_id}`
                // );
                setBorrador(null);
                navigate("/carrito-distribuidor");
                return; // Redirigir y terminar la ejecución aquí
              }
            } catch (error) {
              // Si hay un error en el borrado del borrador, manejar el error
              if (
                error.response &&
                error.response.status === 400 &&
                error.response.data.msg === "No hay borrador"
              ) {
                // Si no hay borrador, redirigir al carrito
                console.log("No hay borrador, redirigiendo al carrito.");
                // setBorrador(null)
                navigate("/carrito-distribuidor");
                return;
              } else {
                console.error("Error al borrar el borrador:", error);
              }
            }
          } else {
            // Si pagarDespuesStatus es falso, validar si tiene borrador
            if (borrador) {
              // Si tiene borrador, navegar a la página de pago pendiente
              navigate("/pago-pendiente");
              return;
            } else {
              // Si no tiene borrador, navegar al carrito
              navigate("/carrito-distribuidor");
              return;
            }
          }
        } catch (error) {
          // Manejar el error 404 y navegar según el estado del borrador
          if (error.response && error.response.status === 404) {
            const errorData = error.response.data;
            if (errorData.status === false) {
              // Si el status es false y no hay datos, manejar el flujo de acuerdo al borrador
              if (borrador) {
                // Si tiene borrador, navegar a la página de pago pendiente
                navigate("/pago-pendiente");
                return;
              } else {
                // Si no tiene borrador, navegar al carrito
                navigate("/carrito-distribuidor");
                return;
              }
            } else {
              toast.error("Error al verificar las facturas pendientes.");
              return;
            }
          } else {
            console.error("Error en la petición:", error);
          }
        }
      }

      // Determinar la ruta según el estado de las facturas y el borrador
      if (allInvoice.length === 0) {
        if (paymentType === "Contado" && !borrador) {
          // Caso de contado sin borrador
          navigate("/carrito-distribuidor");
        } else if (borrador) {
          // Si tiene borrador, ir a pago pendiente
          navigate("/pago-pendiente");
        }
      } else {
        // Si hay facturas, ir al carrito
        navigate("/carrito-distribuidor");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated && pedidos?.length) {

      setPedidosArray(
        pedidos.data?.map((item) => {
          return {
            ...item,
            carrito: item.carrito?.map((el) => {
              return {
                ...el,
                idPedido: item._id,
              };
            }),
          };
        })
      );
    }
  }, [isAuthenticated, pedidos]);

  return (
    <header className={styles.headerWrapper}>
      <nav className={styles.navWrapper}>
        <section className={styles.navWrapperLeftLogos}>
          {/* sidebar */}
          <Sidebar
            showSidebar={showSidebar}
            handleClose={handleClose}
            setShowSidebar={setShowSidebar}
          />
          <button onClick={handleShow} className={styles.sidebarButton}>
            <SvgSidebar />
          </button>

          {/* Logo  */}
          <Link to={"/home-distribuidor"} onClick={handleScrollToTop}>
            <LogoVogue />
          </Link>
        </section>

        {/* Buscador  */}
        {size.width < 750 ? (
          <div
            className={styles.searchWrapperResponsive}
            style={{
              display: showSearch ? "flex" : "none",
            }}
          >
            <button
              className={styles.searchIconWrapperResponsive}
              onClick={() => buscarProductos(null)}
            >
              <SvgBuscador />
            </button>
            <div className={styles.sectionInput} ref={wrapperRef}>
              <input
                type="text"
                className={styles.searchInputResponsive}
                ref={inputRef}
                placeholder="Buscar"
                value={query}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
              />
              {suggestions.length > 0 && (
                <ul
                  className={
                    userType === "DIS"
                      ? styles.desplegableDisResponsive
                      : styles.styles.suggestionsList
                  }
                >
                  {suggestions.map((product, index) => (
                    <li
                      className={styles.optiondesplegableDis}
                      key={index}
                      onClick={() => handleClick(product)}
                      tabIndex="0"
                    >
                      {product.descripcion_prod}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.wrapperSearchMain} ref={wrapperRef}>
            <div
              className={
                suggestions.length > 0
                  ? styles.searchWrapperSuggestions
                  : styles.searchWrapper
              }
            >
              <button
                className={styles.searchIconWrapper}
                onClick={() => buscarProductos(null)}
              >
                <SvgBuscador />
              </button>
              <input
                className={styles.searchInput}
                type="text"
                ref={inputRef}
                placeholder="Buscar"
                value={query}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
              />

              {suggestions.length > 0 && (
                <ul
                  className={
                    userType === "DIS"
                      ? styles.desplegableDis
                      : styles.suggestionsList
                  }
                >
                  {suggestions.map((product, index) => (
                    <li
                      className={styles.optiondesplegableDis}
                      key={index}
                      onClick={() => handleClick(product)}
                      tabIndex="0"
                    >
                      {product.descripcion_prod}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}

        {/* section buttons  */}
        <div className={styles.navIconsWrapper}>
          <div className={styles.searchIcon}>
            {showSearch ? (
              <SvgClosedButtonSearch
                onClick={() => setShowSearch(!showSearch)}
              />
            ) : (
              <SvgBuscador onClick={() => setShowSearch(!showSearch)} />
            )}
          </div>

          <button
            className={styles.cartButton}
            onClick={handleClickCarrito} // Ejecuta handleClick en cada clic
            disabled={loadingBorrador}
          >
            {loading ? (
              <Spinner
                animation="border"
                role="status"
                style={{ color: "#89182f" }}
              >
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            ) : (
              <>
                <SvgCarrito />
                <p className={styles.cartText}>Carrito</p>
              </>
            )}
          </button>

          <div className={styles.logginButton}>
            <Link to={"/tienda-linea-cliente/05"}>
              <FaStore
                className={styles.iconEcommerce}
                color="#89182f"
                size={30}
              />
              <span className={styles.logginButtonText}>Haz tu pedido</span>
            </Link>
          </div>

          {/* Iniciar Seccion  */}
          <DropdownButton
            className={styles.dropdownButton}
            id="dropdown-basic-button"
            title={
              <span className={styles.userDates}>
                <LuUserCircle2 color="white" size={25} />
                <span className={styles.nameUser}>
                  Hola {nombreFormateado}!
                </span>
              </span>
            }
            variant="white"
          >
            <Dropdown.Item
              onClick={() => navigate("/datos-generales-distribuidor")}
            >
              Datos generales
            </Dropdown.Item>
            <Dropdown.Item onClick={() => navigate("/facturas-pendientes")}>
              Facturas pendientes
            </Dropdown.Item>

            <Dropdown.Item onClick={() => navigate("/estado-cuenta")}>
              Estado de cuenta
            </Dropdown.Item>
            <Dropdown.Item>
              <button className={styles.cerrarSecions} onClick={handleLogout}>
                Cerrar sesión
              </button>
            </Dropdown.Item>
          </DropdownButton>
        </div>

        {/* section buttons responsive */}
        <div className={styles.navIconsWrapperResponsive}>
          <div className={styles.searchIcon}>
            {showSearch ? (
              <SvgClosedButtonSearch
                onClick={() => setShowSearch(!showSearch)}
              />
            ) : (
              <CiSearch
                color="#89182f"
                size={25}
                onClick={() => setShowSearch(!showSearch)}
              />
            )}
          </div>

          <button
            className={styles.cartButton}
            disabled={loadingBorrador}
            onClick={handleClickCarrito}
          >
            {loading ? (
              <Spinner
                animation="border"
                role="status"
                style={{ color: "#89182f" }}
                size="sm"
              >
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            ) : (
              <>
                <SvgCarritoResponsive />
                <p className={styles.cartText}>Carrito</p>
              </>
            )}
          </button>

          <div className={styles.logginButton}>
            <Link to={"/tienda-linea-cliente/05"}>
              <FaStore
                className={styles.iconEcommerce}
                color="#89182f"
                size={20}
              />
              <span className={styles.logginButtonText}>E-commerce</span>
            </Link>
          </div>

          {/* Iniciar Seccion  */}
          <DropdownButton
            className={styles.dropdownButton}
            id="dropdown-basic-button"
            title={
              <span className={styles.userDates}>
                <LuUserCircle2 color="white" size={20} />
                <span className={styles.nameUser}>
                  Hola {nombreFormateado}!
                </span>
              </span>
            }
            variant="white"
          >
            <Dropdown.Item
              onClick={() => navigate("/datos-generales-distribuidor")}
            >
              Datos generales
            </Dropdown.Item>
            <Dropdown.Item onClick={() => navigate("/facturas-pendientes")}>
              Facturas pendientes
            </Dropdown.Item>

            <Dropdown.Item onClick={() => navigate("/estado-cuenta")}>
              Estado de cuenta
            </Dropdown.Item>
            <Dropdown.Item>
              <button className={styles.cerrarSecions} onClick={handleLogout}>
                Cerrar sesión
              </button>
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </nav>
    </header>
  );
};
