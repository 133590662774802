import styles from "./Banner.module.css";
import { Link } from "react-router-dom";

export const BannerProductoFavorito = () => {


  return (
    <div className={styles.bannerFavorito}>
      <div className={styles.texto}>

        <Link to={`/detalle/017816`} className={styles.producto}>Comprar aquí</Link>
      </div>
    </div>
  );
};
