import React, { useEffect, useState } from "react";
import styles from "../Perfil/Pedidos.module.css";
import { ProductosRelacionados } from "../ProductosRelacionados";
import axios from "axios";
import { useSelector } from "react-redux";
import { Cargando } from "../../../../homeComun/components/Cargando/Cargando";
import { InfoNotAvailable } from "../../../InfoNotAvailable";
import defaul from "../../../../assets/img/default.png";

export const Pedidos = () => {
  const [productos, setProductos] = useState([]);
  const [detallesFactura, setDetallesFactura] = useState(null);
  const [loading, setLoading] = useState(true);

  const { token, user, userType } = useSelector((state) => state.auth);
  const isDistributor = userType === "DIS";

  const fetchPedidos = async () => {
    try {
      const resPedido = await axios.get(`${process.env.REACT_APP_API_URL}/documentos`, {
        params: {
          email: isDistributor ? undefined : user.email,
          id: isDistributor ? user.cliente_id : undefined,
        },
        headers: { Authorization: token ? `Bearer ${token}` : "" },
      });

      if (resPedido.data?.detalle?.length > 0) {
        const facturaNo = resPedido.data.detalle[0]?.factura_no;
        const resFactura = await axios.get(
          `${process.env.REACT_APP_API_URL}/documentos?id_doc=${facturaNo}`,
          { headers: { Authorization: token ? `Bearer ${token}` : "" } }
        );
        setDetallesFactura(resFactura.data.detalle);
      } else {
        console.warn("No se encontraron facturas en la respuesta.");
      }
    } catch (error) {
      console.error("Error al obtener los pedidos:", error);
    }
  };

  const fetchProductos = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/productos?page=1&pageSize=50`);
      setProductos(response.data.detalle || []);
    } catch (error) {
      console.error("Error al obtener los productos:", error);
      setProductos([]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([fetchPedidos(), fetchProductos()]);
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return <Cargando />;
  }

  return (
    <div className={styles.cajaPadrePedidos}>
      <h1 className={styles.resumen}>Resumen de la compra</h1>
      <div className={styles.ordersWrapper}>
        {detallesFactura?.detalles_factura && Array.isArray(detallesFactura.detalles_factura) ? (
          detallesFactura.detalles_factura
            .filter(detalle => !detalle.descripcion.startsWith("CARGO ADMINISTRATIVO")) // Filtra los cargos administrativos
            .map((detalle, index) => {
              // Filtrar los cargos administrativos
              const cargosAdministrativos = detallesFactura.detalles_factura.filter(detalle =>
                detalle.descripcion.startsWith("CARGO ADMINISTRATIVO")
              );

              // Calcular el subtotal de los productos
              const subtotalProductos = detallesFactura.detalles_factura
                .filter(detalle => !detalle.descripcion.startsWith("CARGO ADMINISTRATIVO"))
                .reduce((total, detalle) => total + parseFloat(detalle.subtotal || 0), 0);

              // Calcular el total de los cargos administrativos
              const subtotalCargos = cargosAdministrativos.reduce((total, cargo) => total + parseFloat(cargo.subtotal || 0), 0);

              // Sumar el total de productos con el total de los cargos administrativos
              const total = subtotalProductos + subtotalCargos;

              return (
                <div key={index} className={styles.contenedorProductos}>
                  {/* Renderiza los detalles del pedido */}
                  <div className={styles.contenedorHistorial}>
                    <img className={styles.img} src={defaul} alt="" />
                    <p className={styles.desccipcion}>{detalle.descripcion}</p>
                    <div className={styles.contenedorCantidad}>
                      <p className={styles.cantidad}>{detalle.cantidad}x</p>
                      <p className={styles.precio}>${parseFloat(detalle.subtotal).toFixed(2)}</p> {/* Convierte a número y fija los decimales */}
                    </div>
                  </div>
                  <p className={styles.lineas}></p>
                  <div className={styles.contenedorTotal1}>
                    <h4 className={styles.total}>Envio:</h4>
                    {cargosAdministrativos.map((cargo, index) => (
                      <p className={styles.precioTotal} key={index}>${parseFloat(cargo.subtotal).toFixed(2)}</p>
                    ))}
                  </div>

                  <div className={styles.contenedorTotal}>
                    <h4 className={styles.total}>Total:</h4>
                    <p className={styles.precioTotal}>${total.toFixed(2)}</p>
                  </div>

                  <p className={styles.lineas}></p>
                </div>
              );
            })
        ) : (
          <InfoNotAvailable message="No hay detalles de la factura disponibles." />
        )}
      </div>

      {productos.length > 0 && productos[30] && (
        <ProductosRelacionados data={productos[30]} />
      )}
    </div>
  );
};
