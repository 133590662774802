import banner from "../../../assets/img/Banner/Mesa de trabajo 4.png";
import banner2 from "../../../assets/img/nuevosBanners/Lonuevo-BannerSitioWeb-04.jpg";
import styles from "../VerTodoProductos/VerTodo.module.css";
import { Card } from "../Cards/Card";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BotonCompra } from "../../../HomeDistribuidor/components/TiendaEnLinea/BotonCompra";
import { Cargando } from "../Cargando/Cargando";
import { Inventario } from "../../../HomeDistribuidor/components/TiendaEnLinea/Inventario";

export const ProductoNuevoResultado = () => {
  const { userType } = useSelector((state) => state.auth);
  const images = [
    { image: banner, text: "Haz click" },
    { image: banner2, text: "Descubrir más" },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [productos, setProductos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 6000);

    return () => clearInterval(interval);
  }, [images.length]);

  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/productos/promociones`
      );
      const productosFiltrados = response.data.detalle.filter(
        (producto) => producto.tipo_incentivo_id === "n"
      );
      setProductos(productosFiltrados);
    } catch (error) {
      console.error("Error al obtener los datos", error);
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    const timeout = setTimeout(() => {
      getData();
    }, 500); // Ejecuta después de 500 ms

    // Limpieza del timeout si el componente se desmonta antes de que se complete
    return () => clearTimeout(timeout);
  }, []);



  const queries = ["1054020", "017835"];
  const estilos =
    currentIndex === 0
      ? ["contenedorNuevo", "NuevoTwoBtn"]
      : ["contenedorNuevo2", "NuevoTwoBtn2"];

  if (isLoading) {
    return <Cargando />;
  }

  return (
    <>
      <div className={styles.carrusels}>
        {images.map((item, index) => (
          <div
            key={index}
            style={{ display: index === currentIndex ? "block" : "none" }}
          >
            <img
              style={{ width: "100%" }}
              src={item.image}
              alt={`Banner ${index + 1}`}
            />
             <BotonCompra
              text={item.text}
              path={`/detalle/${queries[currentIndex]}`} // Usamos directamente el producto_id de queries
              currentIndex={currentIndex}
              index={index}
              estilos={estilos}
            />
          </div>
        ))}
      </div>
      <h2 className={styles.titulo}>Productos nuevos</h2>
      {productos.length > 0 && !isLoading ? (
        <div className={styles.contenedor}>
          <div className={styles.contyenedorCards}>
            {productos.map((ele, index) => (
              <Card
                key={index}
                data={ele}
                agregar="pedido"
                cantidadProp={userType === "DIS" ? true : false}
                detalle={true}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className={styles.contenedorInventario}>


          <Inventario />
        </div>
      )}
    </>
  );
};
