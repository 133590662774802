import React, { useEffect, useState } from "react";
import styles from "./Facturas.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ColumDocument } from "../components/ColumDocument";
import axios from "axios";
import { Invoice } from "../components/Invoice";
import { toast } from "react-toastify";
import { useLoading } from "../../state/context/loadingContext";
import { Spinner } from "react-bootstrap";
import { PagoDistribuidor } from "../components/PagarDistribuidor/PagoDistribuidor";
import { redondearDecimales } from "../../hooks/use-redondearDecimales";

export const FacturasPendientes = () => {
  const { user, isAuthenticated, token, userType } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [loadingGetInvoice, setLoadingGetInvoice] = useState(false);
  const [serfinsa, setSerfinsa] = useState(null);
  const [abonos, setAbonos] = useState(0)
  const [openInvoice, setOpenInvoice] = useState(false);
  const [allInvoice, setAllInvoice] = useState([]);
  const [invoice, setInvoice] = useState(null);
  const [showPagoDistribuidor, setShowPagoDistribuidor] = useState(false);
  const location = useLocation();
  const message = location.state?.message;
  const { isLoading } = useLoading();
  const [pediIdPagarDespues, setPedidoIdPagarDespues] = useState(null);
  const [factura, setFactura] = useState(null);
  const navigate = useNavigate();
  const [saldo, setSaldo] = useState()



  const getFacturaPedidos = async (invoiceNumber) => {
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/documentos?id_doc=${invoiceNumber}`, {
      headers: {
        Authorization: `Bearer ${token ?? ""}`,
      },
    });
    setFactura(response.data);
  };
  // console.log("este es la data", allInvoice);

  const getInvoice = async (facturaNo) => {
    try {
      setLoading(true)
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/clientes?id=${user.cliente_id}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );


      // console.log("factura", facturaNo);

      const abonos = await axios.get(`${process.env.REACT_APP_API_URL}/pedidos/abonos/${facturaNo}`, {
        headers: {
          Authorization: `Bearer ${token ?? ""}`,
        },
      });
      setAbonos(abonos.data.abonos ? abonos.data?.abonos?.reduce((total, obj) => total + obj.monto, 0) : 0)

      if (res.data.detalle.lista_facturas && res.data.detalle.lista_facturas.length > 0) {
        const selectedInvoice = res.data.detalle.lista_facturas.find(invoice => invoice.factura_no === facturaNo);
        if (selectedInvoice) {
          let totalAbonos = abonos.data.abonos ? abonos.data?.abonos?.reduce((total, obj) => total + obj.monto, 0) : 0;
          setInvoice({
            number_invoice: selectedInvoice.factura_no,
            client: selectedInvoice.nombre,
            id_client: selectedInvoice.cliente_id,
            adress: selectedInvoice.direccion,
            date: selectedInvoice.fecha,
            expired: selectedInvoice.vence,
            detailCart: selectedInvoice.detalles_factura,
            total: selectedInvoice.total,
            saldo: selectedInvoice?.saldo,
            balance: selectedInvoice.total - totalAbonos
          });
          await getFacturaPedidos(selectedInvoice.factura_no);
          setOpenInvoice(true);
        }
      }

      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  };

  // console.log(token);

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/")
    }
  }, [])

  const handleGetData = async () => {
    let userIdentifier;
    if (userType === "DIS") {
      userIdentifier = user.cliente_id;
    }
    const resPedido = await axios.get(
      `${process.env.REACT_APP_API_URL}/pedidos/user/${userIdentifier}`,
      {
        headers: {
          Authorization: `Bearer ${token ?? ""}`,
        },
      }
    );
    setSerfinsa(resPedido?.data);
  };






  const handleScrollToTop = async (monto, tipoPago) => {


    //sumar todo los intereses





    setLoading(true);
    try {
      if (isAuthenticated) {
        let res = await axios.post(
          `${process.env.REACT_APP_API_URL}/create-payment`,
          {
            Monto: monto,
            ConceptoPago: "Pago de cosmeticos",
            Adicionales: "DATOS ADICIONALES",
            facturaId: invoice.number_invoice,
            UrlOrigin: "https://vogueelsalvador.com/home-cliente/informacio-pago",
            UrlRedirect: `https://vogueelsalvador.com/finalizar-abonos/${invoice.number_invoice}`,
          },
          {
            headers: {
              Authorization: `Bearer ${token ?? ""}`,
            },
          }
        );
        const { Satisfactorio, Datos } = res.data;
        if (Satisfactorio) {
          window.location.href = `https://www.serfinsacheckout.com/${Datos?.UrlPost}`;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetInvoice = async () => {
    setLoadingGetInvoice(true);
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_API_URL}/clientes?id=${user.cliente_id}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );
      setAllInvoice(res?.data?.detalle?.lista_facturas);
      // console.log(allInvoice);

    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGetInvoice(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      handleGetInvoice();
      handleGetData();
      if (!isLoading && message) {
        toast.success(message);
      }

    }
  }, []);

  const formaPago = () => {
    if (userType === "DIS") {
      return user?.tipo_cre === "CONTADO" ? "Contado" : "Crédito";
    }
    return null;
  };

  // console.log(allInvoice);


  const handleSeleccionarMonto = async () => {
    const selectedInvoice = allInvoice[0];
    const abonos = await axios.get(`${process.env.REACT_APP_API_URL}/pedidos/abonos/${selectedInvoice.factura_no}`, {
      headers: {
        Authorization: `Bearer ${token ?? ""}`,
      },
    });
    let totalAbonos = abonos.data.abonos ? abonos.data?.abonos?.reduce((total, obj) => total + obj.monto, 0) : 0;
    setInvoice({
      number_invoice: selectedInvoice.factura_no,
      client: selectedInvoice.nombre,
      id_client: selectedInvoice.cliente_id,
      adress: selectedInvoice.direccion,
      date: selectedInvoice.fecha,
      expired: selectedInvoice.vence,
      detailCart: selectedInvoice.detalles_factura,
      total: selectedInvoice.total,
      saldo: selectedInvoice?.saldo,
      balance: selectedInvoice.total - totalAbonos
    });
    setShowPagoDistribuidor(true);
  };



  function calcularSaldo(allInvoice) {
    if (!Array.isArray(allInvoice) || allInvoice.length === 0) {
      console.error("El estado allInvoice está vacío o no es válido.");
      return { saldoPagar: 0, detalleFacturas: [] };
    }

    const mesActual = new Date().getMonth() + 1; // Mes actual (1-12)
    const anoActual = new Date().getFullYear(); // Año actual

    // Separar facturas por mes
    const facturasMesesAnteriores = allInvoice.filter(factura => {
      const [anoFactura, mesFactura] = factura.fecha.split("-").map(Number);
      return anoFactura < anoActual || (anoFactura === anoActual && mesFactura < mesActual);
    });

    const facturasMesActual = allInvoice.filter(factura => {
      const [anoFactura, mesFactura] = factura.fecha.split("-").map(Number);
      return anoFactura === anoActual && mesFactura === mesActual;
    });

    let saldoPagar = 0;
    let detalleFacturas = [];

    if (facturasMesesAnteriores.length > 0) {
      // Sumar totales e IVA de meses anteriores
      facturasMesesAnteriores.forEach(factura => {
        const totalConIVA = parseFloat(factura.saldo) + parseFloat(factura.intereses || 0);
        saldoPagar += totalConIVA;
        detalleFacturas.push({
          factura_no: factura.factura_no,
          total: parseFloat(factura.total),
          iva: parseFloat(factura.intereses || 0),
          totalConIVA
        });
      });
    } else if (facturasMesActual.length > 0) {
      // Tomar la factura más antigua del mes actual
      const facturaMasAntigua = facturasMesActual.reduce((anterior, actual) => {
        return new Date(anterior.fecha) < new Date(actual.fecha) ? anterior : actual;
      });

      const totalConIVA = parseFloat(facturaMasAntigua.total) + parseFloat(facturaMasAntigua.intereses || 0);
      saldoPagar = totalConIVA;
      detalleFacturas.push({
        factura_no: facturaMasAntigua.factura_no,
        total: parseFloat(facturaMasAntigua.total),
        iva: parseFloat(facturaMasAntigua.intereses || 0),
        totalConIVA
      });
    }
    // console.log(saldoPagar);
    setSaldo(saldoPagar)
    return { saldoPagar, detalleFacturas };
  }
  useEffect(() => {
    calcularSaldo(allInvoice)
  }, [allInvoice.length > 0])

  const handleClosePagoDistribuidor = () => {
    setShowPagoDistribuidor(false);
  };

  const hasInvoice = allInvoice && allInvoice.length > 0;
  const hasInterest = allInvoice.some((invoice) => parseFloat(invoice.intereses) > 0);
  // console.log("invoice", invoice);

  return (
    <>

      {
        isAuthenticated === true && (

          <div className={styles.contenedorPrincipal}>
            <h1 className={styles.titulo}>Hola, {user.nombres.split(" ")[0]}</h1>
            <div className={styles.contenedor}>
              <p className={styles.parrafos}>
                COD. {user.cliente_id}
                <br />
                DUI: {user.dui}
              </p>
            </div>
            <div className={styles.contenedorDatos}>
              <div className={styles.datos}>
                <Link to={"/datos-generales-distribuidor"} className={styles.parrafo}>
                  Datos generales
                </Link>
                <Link to={""} className={styles.parrafo}>
                  Facturas pendientes
                </Link>
                <Link to={"/estado-cuenta"} className={styles.parrafo}>
                  Estado de cuenta
                </Link>
                {allInvoice.length > 0 && formaPago() === "Crédito" && (
                  <>  <button className={styles.realizarPago} onClick={handleSeleccionarMonto} disabled>
                    {(loadingGetInvoice || loading) ? (
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        className={styles.miSpinner}
                      >
                        <span className="visually-hidden">Cargando...</span>
                      </Spinner>
                    ) : (
                      "Realizar abono"
                    )}
                  </button>
                    <p className={styles.parrafoDiscleimer}>*Los abonos realizados se procesarán en un maximo de  48 horas.</p>
                  </>

                )}
              </div>
              <div className={styles.contenedorTabla}>
                <table className={styles.Tabla}>
                  <thead>
                    <tr>
                      <th>No. factura</th>
                      <th>Fecha</th>
                      <th>Vencimiento</th>
                      <th>Total factura</th>
                      <th>Saldo factura</th>
                      <th>Interés</th>
                    </tr>
                  </thead>
                  {loadingGetInvoice ? (
                    <p className={styles.loadingMessage}>Cargando...</p>
                  ) : allInvoice.length > 0 ? (
                    allInvoice.map((doc, index) => (
                      <>
                        <ColumDocument
                          key={index}
                          allInvoice={doc}
                          getInvoice={getInvoice}
                        />

                      </>

                    ))
                  ) : (
                    <h1 className={styles.messageNoFoundData}>
                      No se encontró facturas pendientes
                    </h1>
                  )}
                </table>
                {hasInterest && hasInvoice && (

                  <p className={styles.textoAdvertencia}>El interés de tu factura varía diariamente.</p>
                )
                }
              </div>
            </div>

            {openInvoice && invoice && (
              <Invoice
                saldosFactura={invoice}
                invoice={factura}
                setOpenInvoice={setOpenInvoice}
                saldo={invoice.total}
                intereses={allInvoice.find(item => item.factura_no === invoice.number_invoice)?.intereses}
                fecha={allInvoice.find(item => item.factura_no === invoice.number_invoice)?.fecha_vencimiento}
              />
            )}
            {showPagoDistribuidor && invoice && (
              <PagoDistribuidor
                // total={allInvoice[0].saldo}
                total={invoice.total}
                handleScrollToTop={handleScrollToTop}
                handleClose={handleClosePagoDistribuidor}
                // saldo={allInvoice.find(item => item.factura_no === invoice.number_invoice)?.saldo}
                saldo={saldo}
                balance={redondearDecimales(invoice.balance, 2)}
              />
            )}
          </div>
        )
      }
    </>
  );
};
