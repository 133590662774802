import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

export const Catalogo = () => {
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const { ref, inView } = useInView({
    triggerOnce: true, // Activa el callback solo una vez
    threshold: 0.1, // Se activa cuando el 10% del elemento es visible
  });

  useEffect(() => {
    if (inView) {
      setIframeLoaded(true);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      style={{ display: "flex", justifyContent: "center", width: "100%", padding: "40px 0" }}
    >
      {iframeLoaded && (
        <div
          style={{
            position: "relative",
            paddingTop: "max(60%,326px)",
            height: 0,
            width: "90%",
          }}
        >
          <iframe
            allow="clipboard-write"
            sandbox="allow-scripts allow-same-origin"
            allowFullScreen
            style={{
              position: "absolute",
              border: "none",
              width: "100%",
              height: "100%",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
            src="https://e.issuu.com/embed.html?d=diciembre-digitalcata_logo2024&u=vogueelsalvador"
          ></iframe>
        </div>
      )}
    </div>
  );
};
