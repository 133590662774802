import React from "react";
import { MenuCategoria } from "../../../../homeComun/components/MenuCategoria/MenuCategoria";
import { NavHomeCliente } from "../NavHomeCliente";
import { Outlet } from "react-router-dom";

export const LayoutCliente = () => {
  return (
    <>
      <NavHomeCliente />
      <MenuCategoria />
      <Outlet />
    </>
  );
};
