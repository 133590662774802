import React, { useEffect, useState } from "react";
import styles from "./TiendaEnLinea.module.css";
import { DespliegueMarcas } from "./DespliegueMarcas";
import axios from "axios";
import { Paginacion } from "../Pagination/Paginacion";
import { useLocation } from "react-router-dom";
import { Card } from "../Cards/Card";
import { useSelector } from "react-redux";
import { Inventario } from "../../../HomeDistribuidor/components/TiendaEnLinea/Inventario";

export const SubMarcas = ({ categorias, index }) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [desplegarMarcas, setDesplegarMarcas] = useState(
    Array(categorias.marcas.length).fill(false)
  );
  const [activeMarca, setActiveMarca] = useState({
    lineaId: null,
    marcaId: null,
  });
  const [marca, setMarca] = useState(null);
  const { isAuthenticated, token, user, userType } = useSelector(
    (state) => state.auth
  );
  const [pedidos, setPedidos] = useState([]);
  const [marcaGuardada, setMarcaGuardada] = useState(null); // Estado para guardar la marca

  const location = useLocation();



  const peticion = async () => {
    if (!activeMarca.lineaId || !activeMarca.marcaId) return;

    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/productos/filter?linea_id=${activeMarca.lineaId}&page=${currentPage}&pageSize=4&marca_id=${activeMarca.marcaId}`
      );
      setData(response.data.detalle);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error al cargar los productos:", error);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    if (!isLoading) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    const delayedPeticion = () => {
      setTimeout(() => {
        peticion();
      }, 1000); // Retraso de 1 segundo (1000 milisegundos)
    };

    delayedPeticion();

  }, [currentPage, activeMarca.lineaId, activeMarca.marcaId]);

  // Función para manejar el despliegue de cada marca
  const handleDespegar = (indexMarca) => {
    const marcaSeleccionada = categorias.marcas[indexMarca];

    const newDesplegarMarcas = desplegarMarcas.map((_, idx) =>
      idx === indexMarca ? !desplegarMarcas[idx] : false
    );
    setDesplegarMarcas(newDesplegarMarcas);

    if (!desplegarMarcas[indexMarca]) {
      setActiveMarca({
        lineaId: categorias.id,
        marcaId: marcaSeleccionada.marcaId,
      });
      setCurrentPage(1);

      // Guardar la marca activa en localStorage
      localStorage.setItem("activeMarca", JSON.stringify({
        lineaId: categorias.id,
        marcaId: marcaSeleccionada.marcaId,
      }));
    }
  };

  const renderCards = () => {
    if (!data || data.length === 0) {
      return <Inventario />;
    }
    return data.map((ele, index) => (
      <Card key={index} data={ele} />
    ));
  };

  useEffect(() => {
    const savedMarca = JSON.parse(localStorage.getItem("activeMarca"));
    if (savedMarca && savedMarca.lineaId === categorias.id) {
      setActiveMarca(savedMarca);
      setMarcaGuardada(savedMarca); // Guardar la marca recuperada
      const element = document.getElementById(categorias.idScroll);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }

    // Scroll suave a la sección correcta después de un retraso
    const element = document.getElementById(location.hash.substring(1));
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });
      }, 2000);
    }
  }, [location]);

  useEffect(() => {
    const storedMarca = JSON.parse(localStorage.getItem('marca'));
    if (storedMarca) {
      setMarca(storedMarca);
    }
  }, []);

  useEffect(() => {
    if (marcaGuardada) {
      const elemento = document.getElementById("marca-seleccionada");
      if (elemento) {
        elemento.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [marcaGuardada]);

  return (
    <>
      {/* Contenedor principal */}
      <div
        className={`${styles.contenedorMarcas} ${index % 2 !== 0 ? styles.marcasInvertida : ""
          }`}
        id={categorias.idScroll}
      >
        {marcaGuardada && (
          <div id="marca-seleccionada" className={styles.marcaGuardada}>

          </div>
        )}
        {/* Contenido de la categoría */}
        <div className={styles.contenedorImg}>
          <img
            className={`${styles.imgPrincipal} ${index === 1 && styles.claseEspecial
              }`}
            src={categorias.imagenPrincipal}
            alt=""
          />
        </div>
        <div className={styles.contenedorDescripcion}>
          <h2 className={styles.titulo}>{categorias.titulo}</h2>
          <p className={styles.parrafo}>{categorias.description}</p>
          <h2 className={styles.marcas}>Marcas</h2>
          <p className={styles.linea}></p>
          <div className={styles.containerDespligue}>
            {/* Desplegar las marcas */}
            {categorias.marcas.map((item, index) => (
              <div key={index}>
                <DespliegueMarcas
                  categorias={item}
                  desplegar={desplegarMarcas[index]}
                  handleDespegar={() => handleDespegar(index)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Mostrar la marca y línea guardadas */}


      {/* Renderizar la información de la marca fuera del contenedor de marcas */}
      {categorias.marcas.map(
        (item, index) =>
          desplegarMarcas[index] && (
            <div
              key={index}
              className={`${styles.contenedorDesplegado} ${desplegarMarcas[index] ? styles.abierto : ""
                }`}
            >
              <img
                className={styles.imgMarcaDesplegado}
                src={item.imagenMarcas[0].img}
                alt=""
              />
              <div className={styles.contenedorDescripcionDesplegado}>
                <h2 className={styles.tituloDesplegado}>{item.nombres}</h2>
                <p className={styles.parrafoDesplegado}>
                  {item.marcaDescription}
                </p>
              </div>
              <div className={styles.cetificacionesColum}>
                {item.imgCertificaciones.length > 0 && (
                  <h3 className={styles.tituloCerrtificaciones}>
                    Certificaciones
                  </h3>
                )}
                <div className={styles.imgsCertificados}>
                  {item.imgCertificaciones.map((imgs, index) => (
                    <img
                      key={index}
                      className={styles.imgDesplegado}
                      src={imgs}
                      alt=""
                    />
                  ))}
                </div>
              </div>
            </div>
          )
      )}

      {/* Renderizar los productos */}
      {categorias.marcas.map(
        (item, index) =>
          desplegarMarcas[index] && (
            <div key={index}>
              <div className={styles.contenedorProductos}>
                {isLoading ? (
                  <h2 className={styles.cargando}>Cargando...</h2>
                ) : (
                  renderCards()
                )}
              </div>
              {/* Renderizar la paginación */}
              <Paginacion
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          )
      )}
      {/* Línea divisoria, si es necesario */}
      {index !== categorias.length - 1 && (
        <div className={styles.lineaDivisoria}></div>
      )}
    </>
  );
};
